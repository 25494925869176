@font-face {
  font-family: 'Outfit';
  src: url(../fonts/Outfit/Outfit-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Outfit';
  src: url(../fonts/Outfit/Outfit-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Outfit';
  src: url(../fonts/Outfit/Outfit-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Nunito';
  src: url(../fonts/Nunito/Nunito-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Nunito';
  src: url(../fonts/Nunito/Nunito-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}
