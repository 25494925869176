body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes fadeIn {
  99% {
    visibility: visible;
  }
  100% {
    visibility: visible;
  }
}
