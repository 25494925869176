@import './fonts.scss';

* {
  letter-spacing: 1%;
}

.page-section {
  margin-bottom: 54px;
}

// .page-section:last-child {
//   margin-bottom: 0;
// }

.page-section > header {
  margin-bottom: 16px;
}

.header {
  margin-bottom: 24px;
}

.blur {
  filter: blur(3px);
  transition: filter 0.2s;
  -webkit-blur: blur(3px);
}

.collapsed {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  transition: filter 0.2s;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
